
























import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import purchaseCommandDeliveryStatuses from "@/constants/purchaseCommandDeliveryStatuses";
import purchaseCommandPaymentStatuses from "@/constants/purchaseCommandPaymentStatuses";
import purchaseCommandTransportLines from "@/constants/purchaseCommandTransportLines";
import makeSelectOptions from "@common/util/makeSelectOptions";
import userManager from "@/services/userManager";
import DataContainer from "@common/modules/vuetifyx/common/DataContainer";
import WarehouseImportsTable from "./purchase-commands/partials/WarehouseImportsTable.vue";
import PurchaseCommandItemsTable from "./purchase-commands/partials/PurchaseCommandItemsTable.vue";
import productGroupTypes from "@/constants/productGroupTypes";
import ItemHistory from "@/components/ItemHistory.vue";
import moment from "moment";

function paymentItemsInput(label) {
  return {
    type: "XArrayInput",
    attrs: {
      label,
      xOptions: {
        content: {
          itemProperties: {
            time: {
              type: "XDateTimePicker",
              attrs: {
                label: "Ngày thanh toán",
                required: true,
              },
            },
            paidAmount: {
              type: "number",
              attrs: {
                label: "Số tiền đã thanh toán (nguyên tệ)",
                required: true,
              },
            },
            exchangeRate: {
              type: "number",
              attrs: {
                label: "Tỷ giá thanh toán",
                required: true,
              },
            },
          },
          template: {
            formOptions: {
              content: {
                colLength: 4,
              },
            },
          },
        },
      },
    },
    ext: {
      colLength: 12,
    },
  };
}

function costItemsInput(label) {
  return {
    type: "XArrayInput",
    attrs: {
      label,
      xOptions: {
        content: {
          itemProperties: {
            time: {
              type: "XDateTimePicker",
              attrs: {
                label: "Ngày",
                required: true,
              },
            },
            amount: {
              type: "number",
              attrs: {
                label: "Số tiền (nguyên tệ)",
                required: true,
              },
            },
            exchangeRate: {
              type: "number",
              attrs: {
                label: "Tỷ giá",
                required: true,
              },
            },
            description: {
              type: "textLarge",
              attrs: {
                label: "Mô tả",
              },
            },
          },
          template: {
            formOptions: {
              content: {
                colLength: 3,
              },
            },
          },
        },
      },
    },
    ext: {
      colLength: 12,
    },
  };
}

function displayFields() {
  return {
    createdTime: {
      text: "Ngày tạo",
      sortable: true,
      options: {
        filter: "dateTime",
      },
    },
    purchaseTime: {
      text: "Ngày mua",
      sortable: true,
      options: {
        filter: "dateTime",
      },
    },
    orderId: {
      text: "Mã ID Order",
      sortable: true,
    },
    orderIdAlt: {
      text: "ID mua hàng NCC",
      sortable: true,
    },
    warehouse: {
      text: "Kho",
      sortable: true,
      options: {
        subProp: "warehouse.name",
      },
    },
    productGroups: {
      text: "Nhóm sản phẩm",
      options: {
        labels: (_, { items }) => {
          const productGroupSkus = new Set<string>();
          items.forEach(({ product }) => {
            if (product.productGroup) {
              productGroupSkus.add(product.productGroup.sku);
            }
          });
          return Array.from(productGroupSkus);
        },
      },
    },
    totalValue: {
      text: "Tổng giá trị order (VND)",
      options: {
        preTransform(_, item) {
          return item.items.reduce((total, it) => {
            return total + (it.count || 0) * (it.unitPrice || 0);
          }, 0);
        },
        filter: "currency",
        transform(value) {
          return `${value} VNĐ`;
        },
      },
    },
    crateCount: {
      text: "Số thùng",
      options: {
        preTransform(_, item) {
          return item.items.reduce((total, it) => {
            return total + (it.crateCount || 0);
          }, 0);
        },
        filter: "number2",
      },
    },
    count: {
      text: "Số lượng tổng",
      options: {
        preTransform(_, item) {
          return item.items.reduce((total, it) => {
            return total + (it.count || 0);
          }, 0);
        },
        filter: "number2",
      },
    },
    localShippingCostPerItem: {
      text: "Đơn giá vận chuyển nguyên tệ",
      sortable: true,
      options: {
        filter: "currency",
      },
    },
    exchangeRate: {
      text: "Tỷ giá",
      sortable: true,
      options: {
        filter: "currency",
        transform(value) {
          return `${value} VNĐ`;
        },
      },
    },
    cbmPerKg: {
      text: "CMB/KG",
      options: {
        filter: "number3",
      },
    },
    shippingCost: {
      text: "Phí ship ước tính (VND)",
      sortable: true,
      options: {
        preTransform(value, item) {
          if (!value) {
            value = 0;
          }
          if (item.localShippingCostPerItem) {
            value = item.localShippingCostPerItem * (item.cbmPerKg || 0) * item.exchangeRate;
            if (item.extraCbmPerKg) {
              value += item.extraLocalShippingCostPerItem * (item.extraCbmPerKg || 0) * (item.extraExchangeRate || item.exchangeRate);
            }
          }
          if (item.shippingCostItems) {
            value += item.shippingCostItems.reduce((total, it) => {
              return total + (it.amount || 0) * (it.exchangeRate || 1);
            }, 0);
          }
          return value;
        },
        filter: "currency",
        transform(value) {
          return `${value} VNĐ`;
        },
      },
    },
    shippingCostUpdateTime: {
      text: "Ngày cập nhật phí ship",
      hidden: true,
      sortable: true,
      options: {
        filter: "dateTime",
      },
    },
    doneCount: {
      text: "Số lượng đã về",
      options: {
        preTransform(_, item) {
          return item.items.reduce((total, it) => {
            return total + (it.doneCount || 0);
          }, 0);
        },
        filter: "number2",
      },
    },
    remainCount: {
      text: "Số lượng chưa về",
      options: {
        preTransform(_, item) {
          return item.items.reduce((total, it) => {
            return total + Math.max((it.count || 0) - (it.doneCount || 0), 0);
          }, 0);
        },
        filter: "number2",
      },
    },
    deliveryStatus: {
      text: "Trạng thái vận chuyển",
      sortable: true,
      options: {
        label(value) {
          return purchaseCommandDeliveryStatuses[value];
        },
        attrs(value) {
          const data: any = {};
          switch (value) {
            case "100":
              data.color = "red";
              break;
            case "500":
              data.color = "green";
              break;
          }
          return data;
        },
      },
    },
    transportLine: {
      text: "Line vận chuyển",
      sortable: true,
      options: {
        label(value) {
          return purchaseCommandTransportLines[value];
        },
      },
    },
    shippingCompany: {
      text: "ĐVVC",
      sortable: true,
    },
    trackingNumber: {
      text: "Tracking Number",
      sortable: true,
    },
    halfwayTime: {
      text: "Ngày hàng về kho trung gian",
      sortable: true,
      options: {
        filter: "dateTime",
      },
    },
    deliveriedTime: {
      text: "Ngày hàng về kho chính",
      sortable: true,
      options: {
        filter: "dateTime",
      },
    },
    estimatedDeliveriedTime: {
      text: "Ngày hàng về dự kiến",
      sortable: true,
      options: {
        filter: "dateTime",
      },
    },
    estimatedDeliveriedCount: {
      text: "Số lượng hàng về dự kiến",
      options: {
        preTransform(_, item) {
          return item.deliveredPlanItems.reduce((total, it) => {
            return total + (it.count || 0);
          }, 0);
        },
        filter: "number2",
      },
    },
    paymentStatus: {
      text: "Trạng thái thanh toán",
      sortable: true,
      options: {
        label(value) {
          return purchaseCommandPaymentStatuses[value];
        },
        attrs(value) {
          const data: any = {};
          switch (value) {
            case "ctt":
              data.color = "red";
              break;
            case "dtt":
              data.color = "green";
              break;
          }
          return data;
        },
      },
    },
    shippingPaymentStatus: {
      text: "Trạng thái thanh toán phí ship",
      sortable: true,
      options: {
        label(value) {
          return purchaseCommandPaymentStatuses[value];
        },
        attrs(value) {
          const data: any = {};
          switch (value) {
            case "ctt":
              data.color = "red";
              break;
            case "dtt":
              data.color = "green";
              break;
          }
          return data;
        },
      },
    },
    totalAmount: {
      text: "Tổng số tiền cần thanh toán (VND)",
      options: {
        preTransform(_, item) {
          let total = item.items.reduce((total, it) => {
            return total + (it.count || 0) * (it.unitPrice || 0);
          }, 0);
          let shippingCost = item.shippingCost || 0;
          if (item.localShippingCostPerItem) {
            shippingCost = item.localShippingCostPerItem * (item.cbmPerKg || 0) * item.exchangeRate;
            if (item.extraCbmPerKg) {
              shippingCost += item.extraLocalShippingCostPerItem * (item.extraCbmPerKg || 0) * (item.extraExchangeRate || item.exchangeRate);
            }
          }
          if (item.shippingCostItems) {
            shippingCost += item.shippingCostItems.reduce((total, it) => {
              return total + (it.amount || 0) * (it.exchangeRate || 1);
            }, 0);
          }
          total += shippingCost;
          return total;
        },
        filter: "currency",
        transform(value) {
          return `${value} VNĐ`;
        },
      },
    },
    totalPaidAmount: {
      text: "Tổng số tiền đã thanh toán (VND)",
      sortable: true,
      options: {
        preTransform(_, item) {
          let paid = item.paidAmount;
          if (item.paymentItems && item.paymentItems.length) {
            paid = item.paymentItems.reduce((total, it) => {
              return total + (it.paidAmount || 0) * (it.exchangeRate || 1);
            }, 0);
          }
          if (item.shippingPaymentItems) {
            paid += item.shippingPaymentItems.reduce((total, it) => {
              console.log(it, total, it.paidAmount, it.exchangeRate);
              return total + (it.paidAmount || 0) * (it.exchangeRate || 1);
            }, 0);
          }
          return paid;
        },
        filter: "currency",
        transform(value) {
          return `${value} VNĐ`;
        },
      },
    },
    totalUnpaidAmount: {
      text: "Tổng số tiền chưa thanh toán (VND)",
      options: {
        preTransform(_, item) {
          let total = item.items.reduce((total, it) => {
            return total + (it.count || 0) * (it.unitPrice || 0);
          }, 0);
          let shippingCost = item.shippingCost || 0;
          if (item.localShippingCostPerItem) {
            shippingCost = item.localShippingCostPerItem * (item.cbmPerKg || 0) * item.exchangeRate;
            if (item.extraCbmPerKg) {
              shippingCost += item.extraLocalShippingCostPerItem * (item.extraCbmPerKg || 0) * (item.extraExchangeRate || item.exchangeRate);
            }
          }
          if (item.shippingCostItems) {
            shippingCost += item.shippingCostItems.reduce((total, it) => {
              return total + (it.amount || 0) * (it.exchangeRate || 1);
            }, 0);
          }
          total += shippingCost;

          let paid = item.paidAmount;
          if (item.paymentItems && item.paymentItems.length) {
            paid = item.paymentItems.reduce((total, it) => {
              return total + (it.paidAmount || 0) * (it.exchangeRate || 1);
            }, 0);
          }
          if (item.shippingPaymentItems) {
            paid += item.shippingPaymentItems.reduce((total, it) => {
              return total + (it.paidAmount || 0) * (it.exchangeRate || 1);
            }, 0);
          }

          return total - paid;
        },
        filter: "currency",
        transform(value) {
          return `${value} VNĐ`;
        },
      },
    },
    paymentNote: {
      text: "Ghi chú thanh toán",
    },
    paidTime: {
      text: "Ngày thanh toán / đặt cọc",
      sortable: true,
      options: {
        filter: "dateTime",
      },
    },
    contactSupplier: {
      text: "Contact Supplier",
    },
    ncc: {
      text: "Nhà cung cấp",
    },
    note: {
      text: "Ghi chú",
    },
    qcdg: {
      text: "Quy cách đóng gói",
    },
    user: {
      text: "Người order",
      options: {
        subProp: "user.name",
        sortBy: "userId",
      },
    },
    description: {
      text: "Mô tả",
    },
    productGroupType: {
      text: "Loại hàng",
      options: {
        label(value) {
          return productGroupTypes[value];
        },
      },
    },
    action: {},
  };
}

export default Vue.extend({
  components: { WarehouseImportsTable, PurchaseCommandItemsTable },
  data() {
    return {
      self: this,
      country: null,
      tableOptions: null,
      importItems: [],
      insertDialogContainer: new DataContainer(),
      userManager,
    };
  },
  methods: {
    makeTableOptions(country) {
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
          "show-select": true,
          "show-expand": true,
        },
        content: {
          name: "Quản lý mua hàng",
          urlEnabled: true,
          search: {},
          displayFields: displayFields(),
          filters: {
            filters: {
              warehouseId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Kho",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("warehouses", "findAll", {
                          payload: JSON.stringify({
                            limit: -1,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: country._id,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              purchaseTime: {
                type: "XDateTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày mua (bắt đầu)",
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày mua (kết thúc)",
                    },
                  },
                },
              },
              paidTime: {
                type: "XDateTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày thanh toán (bắt đầu)",
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày thanh toán (kết thúc)",
                    },
                  },
                },
              },
              deliveriedTime: {
                type: "XDateTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày về kho chính (bắt đầu)",
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày về kho chính (kết thúc)",
                    },
                  },
                },
              },
              orderId: {
                attrs: {
                  label: "Mã ID Order",
                },
                rules: {
                  match: {},
                },
              },
              orderIdAlt: {
                attrs: {
                  label: "ID mua hàng",
                },
                rules: {
                  match: {},
                },
              },
              "items.productId": {
                type: "XAutocomplete",
                attrs: {
                  label: "Sản phẩm",
                  required: true,
                  "item-value": "_id",
                  "item-text": "sku",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("products", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: country._id,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              "items.productGroupId": {
                type: "XAutocomplete",
                attrs: {
                  label: "Nhóm sản phẩm",
                  required: true,
                  "item-value": "_id",
                  "item-text": "sku",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("productGroups", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: country._id,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              deliveryStatus: {
                type: "select",
                attrs: {
                  label: "Trạng thái vận chuyển",
                  multiple: true,
                  items: makeSelectOptions(purchaseCommandDeliveryStatuses),
                },
                rules: {
                  in: {},
                },
              },
              paymentStatus: {
                type: "select",
                attrs: {
                  label: "Trạng thái thanh toán",
                  multiple: true,
                  items: makeSelectOptions(purchaseCommandPaymentStatuses),
                },
                rules: {
                  in: {},
                },
              },
              shippingPaymentStatus: {
                type: "select",
                attrs: {
                  label: "Trạng thái thanh toán phí ship",
                  multiple: true,
                  items: makeSelectOptions(purchaseCommandPaymentStatuses),
                },
                rules: {
                  in: {},
                },
              },
              trackingNumber: {
                attrs: {
                  label: "Tracking number",
                },
                rules: {
                  match: {},
                },
              },
              alerted: {
                type: "boolean",
                attrs: {
                  label: "Cảnh báo",
                },
                rules: {
                  equal_to: {},
                },
              },
            },
          },
          topActionButtons: {
            export: userManager.checkRole(["kt", "qlk"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Xuất Excel",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map((item) => item._id),
                          },
                        ],
                        limit: -1,
                      }),
                    };
                  }
                  coreApiClient.download("purchaseCommands", `@/export`, findAllOptions);
                },
              },
            },
            insert: {
              target: {
                dialog: {
                  attrs: {
                    width: "80%",
                  },
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: {
                target: {
                  dialog: {
                    attrs: {
                      width: "80%",
                    },
                    ext: {
                      subTitleMaker: (item) => item.orderId,
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {},
              actionHistory: {
                content: {
                  icon: "mdi-format-list-bulleted-square",
                },
                target: {
                  tooltip: { content: { text: "Lịch sử tác động" } },
                  dialog: {
                    attrs: {
                      width: "80%",
                    },
                    content: {
                      autofocus: false,
                      toolbar: {
                        title: "Lịch sử tác động",
                        subTitle(self) {
                          const btn = self.context();
                          const { item } = btn.context();
                          return item.orderId;
                        },
                      },
                      contentIsNotDefaultComponent: true,
                      content: {
                        type: ItemHistory,
                        attrs: {
                          targetType: "PurchaseCommand",
                          displayFields: {
                            ...displayFields(),
                            paidAmount: {
                              text: "Số tiền đã thanh toán (VND)",
                            },
                            purchaseShippingCompany: {
                              text: "ĐVVC",
                              options: {
                                subProp: "purchaseShippingCompany.name",
                              },
                            },
                            purchaseCurrency: {
                              text: "Ngoại tệ",
                              options: {
                                subProp: "purchaseCurrency.name",
                              },
                            },
                            items: {
                              text: "Sản phẩm",
                              options: {
                                labelItems(value) {
                                  return `số lượng=${value.count}; id sản phẩm=${value.productId}`;
                                },
                              },
                            },
                            deliveredPlanItems: {
                              text: "Kế hoạch hàng về",
                              options: {
                                labelItems(value) {
                                  return `số lượng=${value.count}; ngày=${moment(value.time).format("DD/MM/YYYY")}`;
                                },
                              },
                            },
                            paymentItems: {
                              text: "Thanh toán đơn hàng",
                              options: {
                                labelItems(value) {
                                  return `đã thanh toán=${Vue.filter("currency")(value.paidAmount)}; tỷ giá=${Vue.filter("currency")(value.exchangeRate)}; ngày=${moment(value.time).format("DD/MM/YYYY")}`;
                                },
                              },
                            },
                            shippingPaymentItems: {
                              text: "Thanh toán phí ship",
                              options: {
                                labelItems(value) {
                                  return `đã thanh toán=${Vue.filter("currency")(value.paidAmount)}; tỷ giá=${Vue.filter("currency")(value.exchangeRate)}; ngày=${moment(value.time).format("DD/MM/YYYY")}`;
                                },
                              },
                            },
                          },
                          hiddenFields: ["orderIdNumber", "market", "productGroups", "country"],
                        },
                        makeAttrs(attrs: any = {}, dialog) {
                          const { item } = dialog.context().context();
                          attrs.item = item;
                          return attrs;
                        },
                      },
                      buttons: {
                        cancel: {
                          content: {
                            text: "Đóng",
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "countryId",
              operator: "equal_to",
              value: country._id,
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("purchaseCommands", "findAll", options);
              return [items, count];
            },
            async insert(item) {
              item.countryId = country._id;
              return await coreApiClient.call("purchaseCommands", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "purchaseCommands",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("purchaseCommands", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              purchaseTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày mua",
                  required: true,
                },
              },
              orderIdAlt: {
                attrs: {
                  label: "Mã ID Order",
                  required: true,
                },
              },
              warehouseId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Kho",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("warehouses", "findAll", {
                          payload: JSON.stringify({
                            limit: -1,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: country._id,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              contactSupplier: {
                attrs: {
                  label: "Contact Supplier",
                },
              },
              note: {
                attrs: {
                  label: "Ghi chú",
                },
              },
              userId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Nhân viên order",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              trackingNumber: {
                attrs: {
                  label: "Tracking number",
                },
              },
              deliveryStatus: {
                type: "select",
                attrs: {
                  label: "Trạng thái vận chuyển",
                  required: true,
                  items: makeSelectOptions(purchaseCommandDeliveryStatuses),
                },
              },
              estimatedDeliveriedTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày hàng về dự kiến",
                  required: true,
                  xOptions: {
                    content: {
                      setDefaultValue: false,
                    },
                  },
                },
              },
              deliveriedTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày hàng về kho chính",
                },
              },
              qcdg: {
                attrs: {
                  label: "Quy cách đóng gói",
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
                ext: {
                  condition() {
                    return userManager.checkRole(["kt"]);
                  },
                },
              },
              shippingCostUpdateTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày cập nhật phí ship",
                },
              },
              ncc: {
                attrs: {
                  label: "Nhà cung cấp",
                },
              },
              purchaseCurrencyId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Loại tiền tệ",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("purchaseCurrencies", "findAll", {
                          payload: JSON.stringify({
                            limit: -1,
                            filters: [],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              purchaseShippingCompanyId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Đơn vị vận chuyển",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("purchaseShippingCompanies", "findAll", {
                          payload: JSON.stringify({
                            limit: -1,
                            filters: [
                              {
                                key: "status",
                                operator: "equal_to",
                                value: "hd",
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              transportLine: {
                type: "select",
                attrs: {
                  label: "Line vận chuyển",
                  required: true,
                  items: makeSelectOptions(purchaseCommandTransportLines),
                },
              },
              productGroupType: {
                type: "select",
                attrs: {
                  label: "Loại hàng",
                  required: true,
                  items: makeSelectOptions(productGroupTypes),
                },
              },
              cbmPerKg: {
                type: "number",
                attrs: {
                  label: "CMB/KG",
                },
              },
              extraPurchaseCurrencyId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Loại tiền tệ",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("purchaseCurrencies", "findAll", {
                          payload: JSON.stringify({
                            limit: -1,
                            filters: [],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              extraPurchaseShippingCompanyId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Đơn vị vận chuyển",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("purchaseShippingCompanies", "findAll", {
                          payload: JSON.stringify({
                            limit: -1,
                            filters: [
                              {
                                key: "status",
                                operator: "equal_to",
                                value: "hd",
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              extraTransportLine: {
                type: "select",
                attrs: {
                  label: "Line vận chuyển",
                  items: makeSelectOptions(purchaseCommandTransportLines),
                },
              },
              extraProductGroupType: {
                type: "select",
                attrs: {
                  label: "Loại hàng",
                  items: makeSelectOptions(productGroupTypes),
                },
              },
              extraCbmPerKg: {
                type: "number",
                attrs: {
                  label: "CMB/KG",
                },
              },
              shippingCostItems: costItemsInput("Chi phí ship bổ sung"),
              halfwayTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày hàng về kho trung gian",
                },
              },
              paymentStatus: {
                type: "select",
                attrs: {
                  label: "Trạng thái thanh toán",
                  required: true,
                  items: makeSelectOptions(purchaseCommandPaymentStatuses),
                },
                ext: {
                  condition() {
                    return userManager.checkRole(["kt"]);
                  },
                },
              },
              shippingPaymentStatus: {
                type: "select",
                attrs: {
                  label: "Trạng thái thanh toán phí ship",
                  required: true,
                  items: makeSelectOptions(purchaseCommandPaymentStatuses),
                },
                ext: {
                  condition() {
                    return userManager.checkRole(["kt"]);
                  },
                },
              },
              paidAmount: {
                type: "number",
                attrs: {
                  label: "Số tiền đã thanh toán",
                },
                ext: {
                  condition() {
                    return userManager.checkRole(["kt"]);
                  },
                },
              },
              paidTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày thanh toán / đặt cọc",
                },
                ext: {
                  condition() {
                    return userManager.checkRole(["kt"]);
                  },
                },
              },

              items: {
                type: "XArrayInput",
                attrs: {
                  label: "Danh sách sản phẩm",
                  xOptions: {
                    content: {
                      itemProperties: {
                        productId: {
                          type: "XAutocomplete",
                          attrs: {
                            label: "Sản phẩm",
                            required: true,
                            "item-value": "_id",
                            "item-text": "sku",
                            xOptions: {
                              content: {
                                async itemsSuggestor(search, value, limit) {
                                  const { items } = await coreApiClient.call("products", "findAll", {
                                    payload: JSON.stringify({
                                      search,
                                      limit,
                                      filters: [
                                        {
                                          key: "countryId",
                                          operator: "equal_to",
                                          value: country._id,
                                        },
                                        {
                                          key: "_id",
                                          operator: "equal_to",
                                          value,
                                        },
                                      ],
                                    }),
                                  });
                                  return items;
                                },
                              },
                            },
                          },
                        },
                        count: {
                          type: "number",
                          attrs: {
                            label: "Số lượng",
                            required: true,
                          },
                        },
                        crateCount: {
                          type: "number",
                          attrs: {
                            label: "Số thùng",
                          },
                        },
                        unitPrice: {
                          type: "number",
                          attrs: {
                            label: "Đơn giá",
                          },
                        },
                        localUnitPrice: {
                          type: "number",
                          attrs: {
                            label: "Đơn giá nguyên tệ",
                          },
                        },
                        cbmPerKg: {
                          type: "number",
                          attrs: {
                            label: "CMB/KG",
                          },
                        },
                        currencyId: {
                          type: "XAutocomplete",
                          attrs: {
                            label: "Loại tiền tệ",
                            required: true,
                            "item-value": "_id",
                            "item-text": "name",
                            xOptions: {
                              content: {
                                async itemsLoader() {
                                  const { items } = await coreApiClient.call("purchaseCurrencies", "findAll", {
                                    payload: JSON.stringify({
                                      limit: -1,
                                      filters: [],
                                    }),
                                  });
                                  return items;
                                },
                              },
                            },
                          },
                        },
                      },
                      template: {
                        formOptions: {
                          content: {
                            colLength: 4,
                          },
                        },
                      },
                    },
                  },
                },
                ext: {
                  colLength: 12,
                },
              },
              deliveredPlanItems: {
                type: "XArrayInput",
                attrs: {
                  label: "Kế hoạch hàng về",
                  xOptions: {
                    content: {
                      itemProperties: {
                        time: {
                          type: "XDateTimePicker",
                          attrs: {
                            label: "Ngày dự kiến về",
                            required: true,
                          },
                        },
                        count: {
                          type: "number",
                          attrs: {
                            label: "Số lượng",
                            required: true,
                          },
                        },
                        note: {
                          type: "textLarge",
                          attrs: {
                            label: "Ghi chú",
                          },
                        },
                      },
                      template: {
                        formOptions: {
                          content: {
                            colLength: 4,
                          },
                        },
                      },
                    },
                  },
                },
                ext: {
                  colLength: 12,
                },
              },
              paymentItems: paymentItemsInput("Thanh toán đơn hàng"),
              shippingPaymentItems: paymentItemsInput("Thanh toán phí ship"),
            },
            insertForm: {
              content: {
                sections: {
                  general: {
                    header: "Thông tin chung",
                    fieldNames: ["purchaseTime", "orderIdAlt", "warehouseId", "contactSupplier", "note", "userId", "trackingNumber", "deliveryStatus", "estimatedDeliveriedTime", "deliveriedTime", "qcdg", "description", "shippingCostUpdateTime", "ncc"],
                  },
                  shippingCost: {
                    header: "Phí ship ước tính",
                    fieldNames: ["purchaseCurrencyId", "purchaseShippingCompanyId", "transportLine", "productGroupType", "cbmPerKg"],
                  },
                  extraShippingCost: {
                    header: "Phí ship bổ sung",
                    fieldNames: ["extraPurchaseCurrencyId", "extraPurchaseShippingCompanyId", "extraTransportLine", "extraProductGroupType", "extraCbmPerKg"],
                  },
                  separeateItems: {
                    header: "Lines",
                    fieldNames: ["items", "deliveredPlanItems"],
                  },
                  payment: {
                    header: "Thanh toán",
                    fieldNames: ["halfwayTime", "paymentStatus", "shippingPaymentStatus", "paidAmount", "paymentNote", "paidTime", "paymentItems", "shippingPaymentItems"],
                  },
                },
                colLength: 3,
              },
            },
            editForm: {
              content: {
                sections: {
                  general: {
                    header: "Thông tin chung",
                    fieldNames: ["purchaseTime", "orderIdAlt", "warehouseId", "contactSupplier", "note", "userId", "trackingNumber", "deliveryStatus", "estimatedDeliveriedTime", "deliveriedTime", "qcdg", "description", "shippingCostUpdateTime", "ncc"],
                  },
                  shippingCost: {
                    header: "Phí ship ước tính",
                    fieldNames: ["purchaseCurrencyId", "purchaseShippingCompanyId", "transportLine", "productGroupType", "cbmPerKg"],
                  },
                  extraShippingCost: {
                    header: "Phí ship bổ sung",
                    fieldNames: ["extraPurchaseCurrencyId", "extraPurchaseShippingCompanyId", "extraTransportLine", "extraProductGroupType", "extraCbmPerKg"],
                  },
                  separeateItems: {
                    header: "Lines",
                    fieldNames: ["items", "deliveredPlanItems"],
                  },
                  payment: {
                    header: "Thanh toán",
                    fieldNames: ["halfwayTime", "paymentStatus", "shippingPaymentStatus", "paidAmount", "paymentNote", "paidTime", "paymentItems", "shippingPaymentItems"],
                  },
                },
                colLength: 3,
              },
            },
            delete: userManager.checkRole(["admin"]) && {
              confirmDisplayField: "orderId",
            },
          },
        },
      };
    },
  },
  async created() {
    const country = await coreApiClient.call("countries", "findById", {
      id: this.$route.params.countryId,
    });
    this.tableOptions = this.makeTableOptions(country);
  },
});
